<template>
  <b-container>
    <b-card>
      <b-row>
        <b-col sm="12" md="6">
          <h3>PAYEMENT OPTIONS</h3>

          <b-list-group
            v-for="collection in accordionItems"
            :key="collection.id"
          >
            <b-list-group-item
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="flex-grow-1 d-flex align-items-center" style="cursor:pointer" @click="manageAccordions(collection.isCollapsed, collection.id)">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      style="
                        background-color: transparent !important;
                        border: none !important;
                      "
                      class="btn btn-icon rounded-circle no-bg"
                      :class="collection.isCollapsed ? null : 'collapsed'"
                      :aria-expanded="collection.isCollapsed ? 'true' : 'false'"
                      aria-controls="collapse-4"
                    >
                      <feather-icon
                        style="color: #1095f4"
                        icon="CircleIcon"
                        size="20"
                        v-if="!collection.isCollapsed"
                      />
                      <i
                        class="ri-record-circle-line"
                        style="font-size: 20px; color: #1095f4"
                        v-else
                      ></i>
                    </b-button>
                  </div>
                  <div class="d-flex align-items-center">
                    <img :src="collection.logo" width="60" height="60" />
                    <span class="ml-2">
                      {{ collection.title }}
                    </span>
                  </div>
                </div>
              </b-card-header>

              <b-collapse
                id="collapse-4"
                v-model="collection.isCollapsed"
                v-if="collection.text"
              >
                <b-card-body>
                  <span v-html="collection.text"></span>
                </b-card-body>
              </b-collapse>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col v-if="selectedPayment" sm="12" md="6" class="mt-1 mt-md-0 mt-lg-0 mt-xl-0">
          <h3>ORDER SUMMARY</h3>
          <div class="coupon mt-1 mb-3">
            <h6>Enter a Gift Card Code.</h6>
            <div>
              <b-input-group>
                <b-form-input
                  type="text"
                  id="coupon-input"
                  v-model="couponCode"
                  @click="resetInput()"
                  placeholder="Gift Card Code"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    :disabled="!couponCode"
                    :variant="couponCode ? 'success' : 'secondary'"
                    @click="coupon()"
                    >Apply</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <small :class="validCoupon ? 'text-success' : 'text-danger'">{{
                messageCoupon
              }}</small>
            </div>
          </div>
          <b-card style="border: 1px solid" v-if="!isLoading">
            <div class="d-flex justify-content-end">
              <h6>Price : {{ normalPrice }} $</h6>
            </div>
            <hr />
            <div class="d-flex justify-content-end">
              <h6>
                Reduction :
                {{ selectedPayment === 2 ? normalPrice : reductionPrice }} $
              </h6>
            </div>
            <hr />
            <div class="d-flex justify-content-end">
              <h6>Total : {{ selectedPayment === 2 ? "0" : totalPrice }} $</h6>
            </div>
          </b-card>
          <b-card v-if="isLoading">
            <div>
              <b-skeleton></b-skeleton>
            </div>
            <hr />
            <div>
              <b-skeleton></b-skeleton>
            </div>
            <hr />
            <div>
              <b-skeleton></b-skeleton>
            </div>
          </b-card>
          <b-button
            block
            variant="success"
            @click="payWithPaypal"
            v-if="selectedPayment === 1"
          >
            <span v-if="!requested">Proceed with paypal payement</span>
            <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          </b-button>
          <b-button
            block
            :variant="validCoupon ? 'success' : 'secondary'"
            :disabled="!validCoupon"
            @click="payWithGiftCard"
            v-if="selectedPayment === 2"
          >
            <span v-if="!requested">Proceed with gift card payement</span>
            <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          </b-button>
          <b-button
            block
            variant="success"
            @click="manualPayement"
            v-if="selectedPayment === 3 || selectedPayment === 4"
          >
            <span v-if="!requested">Submit Order</span>
            <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCollapse,
  BCardHeader,
  BFormInput,
  BCardBody,
  BButton,
  BCardTitle,
  VBTooltip,
  BImg,
  BToggle,
  BFormRadio,
  BCardText,
  VBToggle,
  BCardImg,
  BSkeleton,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import LoaderVue from "@/components/Loader/Loader.vue";
import store from "@/store";
import paypalLogo from "@/assets/images/icons/paypal.svg";
import CouponLogo from "@/assets/images/icons/gift.svg";
// import CouponLogo from "@/assets/images/icons/coupon.svg";
import bankLogo from "@/assets/images/icons/bank.svg";
import usdtLogo from "@/assets/images/icons/usdt.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCollapse,
    BFormInput,
    BSkeleton,
    BCardHeader,
    BCardBody,
    BButton,
    VBTooltip,
    BCardText,
    BCardTitle,
    BCardImg,
    LoaderVue,
    BFormRadio,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BInputGroup,
    BForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      paypalApprovalLink: null,
      requested: false,
      planId: null,
      invoiceId: null,
      selectedPayment: null,
      paypalLogo,
      CouponLogo,
      bankLogo,
      usdtLogo,
      accordionItems: [
        {
          id: 1,
          isCollapsed: false,
          title: "Paypal",
          text: null,
          logo: paypalLogo,
        },
        {
          id: 3,
          isCollapsed: false,
          title: "Bank Transfer",
          text: `
            <span class="payment-transfer">
              <span class="account_name"> Account Name : </span> Nabil Mourchid
            </span>
            <br>
            <span class="payment-transfer">
            <span class="account_name">RIB : </span> 230 825 3859844211029400 59
            </span>
            <br>
            <span class="payment-transfer">
              <span class="account_name"> SWIFT Code : </span> CIHMMAMC
            </span>
            <br>
            <span class="payment-transfer">
            <span class="account_name"> Reason (Motif) : </span> Ecom
            </span>
            <br>
            <br>
            <p class="disc_payment">
            P.S: After making the payment, please send a screenshot of the transaction to the support, along with your email, to activate your account.
            </p>
          `,
          logo: bankLogo,
        },
        {
          id: 4,
          isCollapsed: false,
          title: "USDT",
          text: ` 
            <span class="payment-transfer">
              <span class="account_name">USDT: </span> TTvJttLwFU1g5BFa5eS7qnXAk8ccStfSca
            </span>
            <br>
            <span class="payment-transfer">
              <span class="account_name"> Network: </span> TRX Tron (TRC 20)
            </span>
            <br>
            <br>
            <p class="disc_payment">
            P.S: After making the payment, please send a screenshot of the transaction to the support, along with your email, to activate your account.
            </p>
            `,
          logo: usdtLogo,
        },
        {
          id: 2,
          isCollapsed: false,
          title: "Gift Card",
          text: null,
          logo: CouponLogo,
        },
      ],
      normalPrice: null,
      reductionPrice: 0,
      totalPrice: 0,
      couponCode: null,
      isLoading: false,
      requested: false,
      validCoupon: null,
      messageCoupon: "",
    };
  },
  mounted() {
    this.planId = this.$route.query.plan_id;
    this.invoiceId = this.$route.query.same_id;
    this.fetchPlan();
    // this.checkUserPlan();
  },
  computed: {
    CardBgColor() {
      if (store.state.appConfig.layout.skin === "dark") {
        return "#161d31 !important";
      }
      return "#f6f6f6 !important";
    },
  },

  methods: {
    checkUserPlan() {
      axios
        .post("/checkUserSubscription")
        .then(({ data }) => {})
        .catch((err) => {
          if (err.status === 403) {
            this.$router.push({ name: "dashboard" });
          }
        });
    },
    makeToast(toaster, variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant: variant,
        toaster,
        // solid: true,
      });
    },
    coupon() {
      if (this.selectedPayment === 2) {
        this.checkCoupon();
      } else {
        this.doReduction();
      }
    },
    payWithGiftCard() {
      if (this.validCoupon) {
        axios
          .post("/pay-with-coupon", {
            coupon: this.couponCode,
            invoice_id: this.invoiceId,
            plan_id: this.planId,
            price: this.totalPrice,
          })
          .then(({ data }) => {
            if (data.status === true) {
              // location.href = "https://dev.appsmanager.co/dashboard?message=You Are Now Premuim";
              location.href = "https://app.applead.io/dashboard?message=You Are Now Premuim";
            } else {
              this.validCoupon = false;
              this.messageCoupon = data.message;
              const couponInput = document.getElementById("coupon-input");
              couponInput.classList.remove("is-valid");
              couponInput.classList.add("is-invalid");
            }
          });
      } else {
        this.messageCoupon = "Enter A valid Coupon";
        const couponInput = document.getElementById("coupon-input");
        couponInput.classList.remove("is-valid");
        couponInput.classList.add("is-invalid");
      }
    },
    checkCoupon() {
      axios
        .post("/check-coupon", {
          coupon: this.couponCode,
        })
        .then(({ data }) => {
          const couponInput = document.getElementById("coupon-input");
          if (data.status === true) {
            couponInput.classList.remove("is-invalid");
            couponInput.classList.add("is-valid");
            this.validCoupon = true;
          } else {
            couponInput.classList.remove("is-valid");
            couponInput.classList.add("is-invalid");
            this.validCoupon = false;
          }
          this.messageCoupon = data.message;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    doReduction() {
      axios
        .post("/coupon-reduction", {
          coupon: this.couponCode,
        })
        .then(({ data }) => {
          const couponInput = document.getElementById("coupon-input");
          if (data.status === true) {
            couponInput.classList.remove("is-invalid");
            couponInput.classList.add("is-valid");
            this.validCoupon = true;

            const reductionAmount =
              (parseFloat(data.reduction) / 100) * this.normalPrice;
            this.totalPrice = this.normalPrice - reductionAmount;
            this.totalPrice = this.totalPrice.toFixed(2)
            this.reductionPrice = reductionAmount.toFixed(2);
          } else {
            couponInput.classList.remove("is-valid");
            couponInput.classList.add("is-invalid");
            this.validCoupon = false;
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Wrong",
              data.message
            );
          }

          this.messageCoupon = data.message;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    manageAccordions(isCollapsed, idAccordion) {
      const targetAccordion = this.accordionItems.find(
        (item) => item.id === idAccordion
      );
      if (targetAccordion) {
        targetAccordion.isCollapsed = !isCollapsed;
        this.accordionItems.forEach((item) => {
          if (item.id !== idAccordion) {
            item.isCollapsed = false;
          }
        });
      }
        this.selectedPayment = idAccordion;
      if(isCollapsed) {
        this.selectedPayment = null;
      }
    },
    async fetchPlan() {
      try {
        this.isLoading = true;
        await axios
          .post("/get-plan-by-id", {
            plan_id: this.planId,
          })
          .then(({ data }) => {
            if (data.success) {
              this.selectedPlan = data.data;
              this.normalPrice = parseFloat(this.selectedPlan.price);
              this.totalPrice = this.normalPrice;
            } else {
              // this.goBack();
            }
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
      }
    },
    async payWithPaypal() {
      try {
        this.requested = true;
        const response = await axios.post("/paypal-payement", {
          planId: this.planId,
          invoiceId: this.invoiceId,
          reductionPrice: this.reductionPrice,
          price: this.totalPrice,
        });
        if (response.status === 200) {
          const paypalApprovalLink = response.data.link;
          window.location.href = paypalApprovalLink;
          this.requested = false;
        }
      } catch (error) {
        this.requested = false;
      }
    },
    resetInput() {
      this.messageCoupon = "";
      const couponInput = document.getElementById("coupon-input");
      couponInput.classList.remove("is-invalid");
      couponInput.classList.remove("is-valid");
    },
    manualPayement() {
      try {
        axios
          .post("/update-invoice-for-manual-paying", {
            method: this.selectedPayment,
            invoiceId: this.invoiceId,
            reductionPrice: this.reductionPrice,
            price: this.totalPrice,
          })
          .then(({ data }) => {
            if (data.success) {
              this.showToast("success", "SaveIcon", "Success", data.message);
            } else {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Wrong",
                data.message
              );
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {}
    },
  },
};
</script>

<style>
.payment-transfer .account_name,.numero {
  font-weight: bold !important;
}
.payment-transfer,.disc_payment {
  font-size: 16px;
}
</style>
